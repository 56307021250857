$testimonial-width: 366px;
$arrow-diameter: 60px;

#grid-slider .slider-header {
  display: flex;
  align-items: baseline;
  margin: 0px 10px;

  @include bp(lg) {
    justify-content: space-between;
    width: 100%;
    max-width: 644px;
  }

  @include bp(md) {
    justify-content: space-between;
    width: 100%;
    max-width: 644px;
  }
}

.homeslider-tagline {
  color: black;
  position: absolute;
  width: 90%;
  font-size: 48px;
  max-width: fit-content;
  text-align: left;
  font-weight: 600;
  letter-spacing: -1.44px;
  left: 11%;

  @include bp(md, max) {
    position: relative;
    margin-bottom: 32px;
    left: 2px;
  }

  @include bp(lg) {
    margin-left: auto;
    margin-right: auto;
    left: 9%;
  }
}

// styles for the grid slider
.grid-slider.splide{

  .cta {
    display: flex;
    justify-content: center;
  }

  .splide__track {
    @include bp(lg, max) {
      width: 80%;
      left: 10%;
      right: 10%;
      margin-bottom: 20px;
    }
    @include bp(lg) {
      width: 1150px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 64px;
      margin-bottom: 64px;
    }
    @include bp(md, max) {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      left: 0px;
      right: 0px;
    }
  }

  .splide__arrows {
    @include bp(lg) {
      width: 1144px;
      margin-left: auto;
      margin-right: auto;
    }
    @include bp(md, max) {
      margin-left: auto;
    }

    &.rc-slide-arrows {
      padding-left: 10px;
      margin-bottom: 40px;

      @include bp(md, max) {
        margin-bottom: 0px;
      }

      @include bp(640px) {
        padding-left: 10.5%;
      }

      @include bp(md) {
        position: absolute;
        top: -1%;
        right: 10%;
        margin-bottom: 0;
        padding-left: 0;
      }

      @include bp(lg) {
        top: 3%;
      }
    }
  }

  // Arrow Tweaks
  .splide__arrow {
    top: 50px;
    height: $arrow-diameter;
    transform: none;
    border-radius: 100%;
    width: $arrow-diameter;
    opacity: 1;
    @include bp(lg) {
      position: relative;
      display: inline-block;
      left: 1014px;
      top: 0px;
      img {
        margin-top: 5px;
      }
    }
    @include bp(md, max) {
      position: absolute;
      display: inline-block;
      top: 98%;
      width: 48px;
      height: 48px;
      img {
        margin-top: 5px;
      }
    }
    @include bp(639px, max) {
      position: absolute;
      display: inline-block;
      top: 98%;
    }
  }

  .splide__arrow.splide__arrow--prev {
    transform: scaleX(-1);
    left: auto;
    opacity: 1;
    right: calc(11% + 62px);
    background: var(--qkn-light-blue, #F3F8FD);
    @include bp(lg) {
      left: 1007px;
    }
    @include bp(md, max) {
      position: absolute;
      left: 50%;
      margin-left: -60px;
    }
  }

  .splide__arrow.splide__arrow--next {
    right: 10%;
    background: var(--qkn-light-blue, #F3F8FD);
    @include bp(md, max) {
      left: calc(50% + 10px);
    }
  }

  .splide__arrow.splide__arrow--prev,
  .splide__arrow.splide__arrow--next {
    @include bp(md, max) {
      &.rc-slider-arrow {
        position: initial;
        margin: 0;
      }
    }
  }

  .splide__arrow.splide__arrow--prev:disabled {
    opacity: 0.5;
  }

  .splide__arrow.splide__arrow--next:disabled {
    opacity: 0.5;
  }

  .splide__arrow.splide__arrow--next:enabled:hover {
    background: var(--qkn-blurple, #471CFF);
    img {
      filter: invert(1);
    }
  }

  .splide__arrow.splide__arrow--prev:enabled:hover {
    background: var(--qkn-blurple, #471CFF);
    img {
      filter: invert(1);
    }
  }

  .splide__arrows.top-arrows {
    @include bp(md, max) {
      display: none;
    }
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: $testimonial-width;
    background-color: var(--qkn-light-blue, #F3F8FD);
    border-radius: $border-radius-default;
    position: relative;
    margin: auto;
    @include bp(lg, max) {
      width: 95%;
    }
    @include bp(639px, max) {
      width: 98.773%;
    }
  }

  .testimonial-stars {
    display: flex;
    position: relative;
    color: var(--star-yellow, #FFE629);
    height: 25px;
    margin-top: 40px;
    margin-left: 40px;
  }

  .testimonial-star {
    width: 22px;
    height: 22px;
    margin-right: 1px;
  }


  .testimonial-quote {
    position: relative;
    font-size: 17px;
    margin: 0px 40px;
    height: auto;
    margin-bottom: 8px;
    margin-top: 10px;
    color: var(--darkGray, #18181F);
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;

    * {
      display: inline;
    }
  }

  .testimonial-author {
    position: relative;
    margin-bottom: 40px;
    margin-left: 42px;
    gap: 16px;
    margin-top: auto;
    .trustpilot_logo {
      width: 103px;
      height: auto;
    }
  }

  .testimonial-author.hug {
    height: 47px;
  }

  .testimonial-author > p {
    margin: 0;
    padding: 0;
  }

  .testimonial-name {
    position: relative;
    color: var(--qkn-blurple);
    font-weight: 600;
    width: 140px;
    height: 22px;
    text-align: left;
  }

  .rc-slider {
    // gap: 19px;

    .splide__slide {
      width: 308px;
      @include bp(md) {
        width: 365px;
      }
    }

    .rec-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .slide-link {
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        text-decoration: none;
        z-index: 1;

        &:hover {
          background: none;
        }
      }

      .corner-icons {
        position: absolute;
        bottom: 0px;
        right: 0px;

        &.left-curve {
          right: 53px;
        }

        &.top-curve {
          bottom: 52px;
        }
      }

      .slide-img {
        border-radius: 12px;
        position: relative;

        .main-img {
          width: 100%;
          height: 174px;
          border-radius: 12px;

          @include bp(md) {
            height: 206px;
          }
        }




      }

      .rc-content {
        padding-top: 16px;
        height: 100%;

        .rc-tags {
          span {
            font-size: 14px;
            color: var(--qkn-light-gray);
          }
        }
      }

      .rc-title {
        padding-top: 8px;
        color: var(--qkn-medium-gray);
        font-size: 21px;

        @include bp(md, max) {
          font-size: 26px;
        }

      }

      &.layout2 {
        .slide-img {
          border-radius: unset;

          .main-img {
            border-radius: 15px 15px 0px 0px;
          }
        }

        .rc-content {
          padding: 13px 32px 51px 32px;
        }
      }

      &.rc-bg-white {
        background-color: var(--white);
      }

      &.rc-bg-gray {
        background-color: var(--card-gray);
      }

    }
  }
}

// styles for the multi card slider
.homepage-slider.splide{
  @include bp(md, max) {
    margin-bottom: 55px;
  }

  .cta {
    display: flex;
    justify-content: center;
  }

  .splide__track {
    @include bp(lg, max) {
      width: 80%;
      left: 10%;
      right: 10%;
      margin-bottom: 10px;
    }
    @include bp(lg) {
      width: 1150px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 64px;
    }
    @include bp(md, max) {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      left: 0px;
      right: 0px;
    }
  }

  .slider-header {
    display: inline-flex;
    justify-content: space-between;
    max-width: 95%;
  }

  .splide__arrows {
    @include bp(lg) {
      margin-left: auto;
      margin-right: auto;
    }
    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
    }

    &.rc-slide-arrows {
      display: flex;
      gap: 10px;
      padding-left: 10px;
      margin-bottom: 40px;

      @include bp(640px) {
        padding-left: 10.5%;
      }

      @include bp(md) {
        position: absolute;
        top: -1%;
        right: 10%;
        margin-bottom: 0;
        padding-left: 0;
        gap: 0px;
      }

      @include bp(lg) {
        width: 1144px;
        top: 7%;
      }
    }
  }

  // Arrow Tweaks
  .splide__arrow {
    top: 50px;
    height: $arrow-diameter;
    transform: none;
    border-radius: 100%;
    width: $arrow-diameter;
    opacity: 1;
    @include bp(lg) {
      position: relative;
      display: inline-block;
      left: 1174px;
      top: 0px;

      img {
        margin-top: 5px;
      }
    }
    @include bp(md, max) {
      position: absolute;
      display: inline-block;
      top: 98%;
      width: 48px;
      height: 48px;
      img {
        margin-top: 5px;
      }
    }
    @include bp(639px, max) {
      position: absolute;
      display: inline-block;
      top: 98%;
    }
  }

  .splide__arrow.splide__arrow--prev {
    transform: scaleX(-1);
    left: auto;
    opacity: 1;
    right: calc(11% + 62px);
    background: var(--qkn-light-blue, #F3F8FD);

    @include bp(lg) {
      left: 1167px;
    }
    @include bp(md, max) {
      position: absolute;
      left: 50%;
      margin-left: -60px;
    }
  }

  .splide__arrow.splide__arrow--next {
    right: 10%;
    background: var(--qkn-light-blue, #F3F8FD);
    @include bp(md, max) {
      left: calc(50% + 10px);
    }
  }

  .splide__arrow.splide__arrow--prev,
  .splide__arrow.splide__arrow--next {
    @include bp(md, max) {
      &.rc-slider-arrow {
        position: initial;
        margin: 0;
      }
    }
  }

  .splide__arrow.splide__arrow--prev:disabled {
    opacity: 0.5;
  }

  .splide__arrow.splide__arrow--next:disabled {
    opacity: 0.5;
  }

  .splide__arrow.splide__arrow--next:enabled:hover {
    background: var(--qkn-blurple, #471CFF);
    img {
      filter: invert(1);
    }
  }

  .splide__arrow.splide__arrow--prev:enabled:hover {
    background: var(--qkn-blurple, #471CFF);
    img {
      filter: invert(1);
    }
  }

  .splide__arrows.top-arrows {
    @include bp(md, max) {
      display: none;
    }
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: $testimonial-width;
    background-color: var(--qkn-light-blue, #F3F8FD);
    border-radius: $border-radius-default;
    position: relative;
    margin: auto;
    @include bp(lg, max) {
      width: 95%;
    }
    @include bp(639px, max) {
      width: 98.773%;
    }
  }

  .testimonial-stars {
    display: flex;
    position: relative;
    color: var(--star-yellow, #FFE629);
    height: 25px;
    margin-top: 40px;
    margin-left: 40px;
  }

  .testimonial-star {
    width: 22px;
    height: 22px;
    margin-right: 1px;
  }


  .testimonial-quote {
    position: relative;
    font-size: 17px;
    margin: 0px 40px;
    height: auto;
    margin-bottom: 8px;
    margin-top: 10px;
    color: var(--darkGray, #18181F);
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;

    * {
      display: inline;
    }
  }

  .testimonial-author {
    position: relative;
    margin-bottom: 40px;
    margin-left: 42px;
    gap: 16px;
    margin-top: auto;
    .trustpilot_logo {
      width: 103px;
      height: auto;
    }
  }

  .testimonial-author.hug {
    height: 47px;
  }

  .testimonial-author > p {
    margin: 0;
    padding: 0;
  }

  .testimonial-name {
    position: relative;
    color: var(--qkn-blurple);
    font-weight: 600;
    width: 140px;
    height: 22px;
    text-align: left;
  }

  .rc-slider {
    // gap: 19px;

    .splide__slide {
      width: 308px !important;
      @include bp(md) {
        width: 365px !important;
      }
    }

    .rec-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .slide-link {
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        text-decoration: none;
        z-index: 1;

        &:hover {
          background: none;
        }
      }

      .corner-icons {
        position: absolute;
        bottom: 0px;
        right: 0px;

        &.left-curve {
          right: 53px;
        }

        &.top-curve {
          bottom: 52px;
        }
      }

      .slide-img {
        border-radius: 12px;
        position: relative;

        .main-img {
          width: 100%;
          height: 174px;
          border-radius: 12px;

          @include bp(md) {
            height: 206px;
          }
        }




      }

      .rc-content {
        padding-top: 16px;
        height: 100%;

        .rc-tags {
          span {
            font-size: 14px;
            color: var(--qkn-light-gray);
          }
        }
      }

      .rc-title {
        padding-top: 8px;
        color: var(--qkn-medium-gray);
        font-size: 21px;

        @include bp(md, max) {
          font-size: 26px;
        }

      }

      &.layout2 {
        .slide-img {
          border-radius: unset;

          .main-img {
            border-radius: 15px 15px 0px 0px;
          }
        }

        .rc-content {
          padding: 13px 32px 51px 32px;
        }
      }

      &.rc-bg-white{
        background-color: var(--white);
      }
      &.rc-bg-gray{
        background-color: var(--card-gray);
      }

    }
  }
}

// styles for the single card slider
.save-slider.splide {
  margin-bottom: 50px;
  @include bp(md){
    margin-bottom: 0px;
  }

  .cta {
    display: flex;
    justify-content: center;
  }

  // slide settings for deactivated state
  .splide__slide {
    .testimonial {
      background-color: var(--card-gray, #F0F5FA);
      @include bp(md) {
        .logo_image {
          opacity: 0;
          transition: opacity 600ms;
        }
        .testimonial-quote {
          opacity: 0;
          transition: opacity 600ms;
        }
        .testimonial-author {
          opacity: 0;
          transition: opacity 600ms;
        }
        .quotation-mark-1 {
          opacity: 0;
          transition: opacity 600ms;
        }
      }
    }
  }

  .splide__slide.is-active {
    .testimonial {
      background-color: var(--card-gray, #F0F5FA);
      .logo_image {
        opacity: 1;
      }
      .testimonial-quote {
        opacity: 1;
      }
      .testimonial-author {
        opacity: 1;
      }
      .quotation-mark-1 {
        opacity: 1;
      }
    }

  }

  .splide__arrows {
    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      margin-bottom: -50px;
      height: 50px;
    }
  }

  // Arrow Tweaks
  .splide__arrow {
    top: 45%;
    bottom: 0;
    height: 64px;
    width: 64px;
    transform: none;
    border-radius: 100%;
    opacity: 1;
    svg {
      filter: invert(1);
      width: 24px;
      height: 24px;
    }
    border: 1px solid;
    border-color: var(--bright-gray, #EAECF7);
    filter: drop-shadow(6px 9px 26px rgba(7, 8, 31, 0.06));
    @include bp(md, max) {
      position: absolute;
      display: inline-block;
      border: none;
      height: 48px;
      width: 48px;
      top: 98%;
      filter: none;
      img {
        margin-top: 5px;
      }
    }
  }

  .splide__arrow.splide__arrow--prev {
    left: 100px;
    right: auto;
    background: var(--white, #FFF);
    transform: scaleX(-1);
    @include bp(1300px, max) {
      left: 17px;
    }
    @include bp(md, max) {
      position: absolute;
      background: var(--card-gray, #F0F5FA);
      left: 50%;
      margin-left: -60px;
    }
  }

  .splide__arrow.splide__arrow--next {
    right: 100px;
    background: var(--white, #FFF);
    @include bp(1300px, max) {
      right: 17px;
    }
    @include bp(md, max) {
      background: var(--card-gray, #F0F5FA);
      left: calc(50% + 10px);
    }
  }

  .splide__arrow.splide__arrow--prev:disabled {
    opacity: 0.5;
  }

  .splide__arrow.splide__arrow--next:disabled {
    opacity: 0.5;
  }

  .splide__arrow:hover {
    background: var(--qkn-blurple, #471CFF);
    img {
      filter: invert(1);
    }
  }

  .testimonial {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 360px;
    background-color: var(--qkn-blurple, #471CFF);
    border-radius: $border-radius-default;
    position: relative;
    margin: auto;
    @include bp(md, max) {
      min-height: 330px;
      height: auto;
    }
    .logo_image {
      align-self: flex-start;
      position: relative;
      top: 0px;
      border-radius: $border-radius-default 0px 0px $border-radius-default;
      @include bp(md, max) {
        display: none;
      }
    }
  }

  .testimonial-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .quote-container {
    display: flex;
    flex-direction: row;
    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }

  .quotation-mark-1 {
    position: relative;
    left: -6px;
    top: -5px;
    height: 50px;
    width: 10px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.36px;
    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      left: -3px;
      top: 0;
      font-size: 22px;
      line-height: 135%;
      letter-spacing: -0.095px;
      @include bp(639px, max) {
        font-size: 19px;
      }
    }
  }

  .testimonial-quote {
    position: relative;
    font-size: 22px;
    height: auto;
    margin-right: 50px;
    color: var(--qkn-medium-gray);
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.22px;
    @include bp(md, max) {
      margin-right: 0;
      margin-top: 0px;
      left: 0px;
      font-size: 22px;
      line-height: 135%;
      letter-spacing: -0.095px;
      @include bp(639px, max) {
        font-size: 19px;
      }
    }

    * {
      display: inline;
    }

    .quotation-mark-2 {
      position: absolute;
      margin-top: -4px;
      margin-left: 5px;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.36px;
      @include bp(md, max) {
        font-size: 22px;
        line-height: 135%;
        letter-spacing: -0.095px;
        @include bp(639px, max) {
          font-size: 19px;
        }
      }
    }

  }

  .testimonial-author {
    position: relative;
    gap: 16px;
    @include bp(md, max) {
      margin-left: auto;
      margin-right: auto;
      left: -3px;
      width: 80%;
    }
  }

  .testimonial-author > p {
    margin: 0;
    padding: 0;
  }

  .testimonial-author {
    .trustpilot_logo {
      width: 103px;
      height: auto;
    }
  }

  .testimonial-name {
    color: var(--qkn-blurple, #471CFF);
    font-weight: 600;
    width: 140px;
    height: 22px;
    text-align: left;
  }
}

// styles for the feature card slider
#feature-card-slider {
  .splide__arrows {
    @include bp(md) {
      &.hide-arrows {
        display: none;
      }
    }
  }

  .cta {
    display: flex;
    justify-content: center;
  }

  .splide__track {
    .splide__slide {
      min-width: 305px;
    }
  }

  .splide__arrow.splide__arrow--next:enabled:hover,
  .splide__arrow.splide__arrow--prev:enabled:hover {
    background: var(--qkn-blurple, #471CFF);

    img {
      filter: invert(1);
    }
  }
  .splide__arrow {
    background-color: white;
    border: 1px solid #EAECF7;
    filter: drop-shadow(6px 9px 26px rgba(7, 8, 31, 0.06));

    @include bp(md) {
      top: 50%;
    }

    @include bp(lg) {
      position: absolute;
      left: unset;
    }
  }

  .splide__arrow--next {
    @include bp(md) {
      right: 1em;
    }
  }

  .splide__arrow--prev {
    @include bp(md) {
      left: 1em;
    }
  }

  .feature-list-card {
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-default;
    height: 100%;

    .top-content {
      order: 2;
      padding: 25px 25px 0px 25px;
      line-height: 1.2;
    }

    .img-contain {
      order: 1;

    .feature-img {
        border-top-left-radius: $border-radius-default;
        border-top-right-radius: $border-radius-default;
        max-width: 100%;
        height: auto;
      }
    }


    .feature-contain {
      order: 3;
      padding: 0px 25px 7px;

      .faux-link {
        display: flex;
      }

      li:not(:last-child) {
        margin-bottom: 10px;
      }

      .feature-icon {
        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }
    }
  }
}