section.cards-container .cards .biographical-card {
    $accessTabWidth: 17%;
    $cardFrontWidth: calc(100% - $accessTabWidth);

    position: relative;
    border-radius: 24px;
    width: 335px;
    height: 250px;
    background: var(--card-gray);
    overflow: hidden;
    cursor: pointer;

    @include bp(md) {
        height: 374px;
    }

    &>div {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: calc(100% + $accessTabWidth);

        .identity {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            text-align: left;

            &>img.headshot {
                object-fit: cover;
            }

            &>dl {
                display: flex;
                flex-direction: column;

                &>dt {
                    font-size: 16px;

                    @include bp(md) {
                        font-size: 21px;
                    }
                }
                &>dd {
                    color: var(--qkn-light-gray);
                    font-size: 14px;

                    @include bp(md) {
                        font-size: 16px;
                    }
                }
            }
        }

        &.front {
            padding: 22px calc((32px * 2) + 17%) 22px 32px;

            @include bp(md) {
                padding: 44px calc((29px * 2) + 17%) 44px 29px;
            }

            &>.identity {
                flex-direction: column;
                align-items: flex-start;
                gap: 18px;

                @include bp(md) {
                    gap: 42px;
                }

                &> img.headshot {
                    $radius: 120px;
                    width: $radius;
                    height: $radius;
                    border-radius: $radius;

                    @include bp(md) {
                        $radius: 180px;
                        width: $radius;
                        height: $radius;
                        border-radius: $radius;
                    }
                }

                &> dl {
                    gap: 8px;

                    @include bp(md) {
                        gap: 18px;
                    }

                    dt {
                        font-size: 23px;

                        @include bp(md) {
                            font-size: 30px;
                        }
                    }
                    dd {
                        font-size: 19px;

                        @include bp(md) {
                            font-size: 21px;
                        }
                    }
                }
            }
        }

        &.back {
            background: var(--light-steel-blue);
            margin-left: $cardFrontWidth;
            transition: margin $transition-duration-default ease-out;
            z-index: 3;
            padding: 19px 24px 19px calc(24px + $accessTabWidth);

            @include bp(md) {
                padding: 21px 24px 21px calc(24px + $accessTabWidth);
            }

            &>.arrow {
                $accessTabWidth: calc(100% * (1 - 1 / (1 + $accessTabWidth / 100%)));
                $arrowWidth: 22px;
                position: absolute;
                display: block;
                width: $arrowWidth;
                object-fit: cover;
                text-align: center;
                left: calc(($accessTabWidth - $arrowWidth) / 2);
                bottom: 5%;
                opacity: 0.3;
            }

            &>.content {
                display: flex;
                flex-direction: column;
                gap: 18px;

                &>.identity {
                    &> img.headshot {
                        $radius: 50px;
                        width: $radius;
                        height: $radius;
                        border-radius: $radius;
                    }

                    &>dl {
                        gap: 6px;
                    }
                }

                &>.biography {
                    font-size: 13px;
                    line-height: 140%;
                    text-align: left;

                    @include bp(md) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &.obverse .back {
        margin-left: calc(-100% + $cardFrontWidth);
    }
}