.overflow-hidden {
  overflow: hidden;
}

.navbar {
  position: relative;
  width: 100%;
  max-width: $max-width;
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 18px 20px;
  min-height: 60px;
  overflow: hidden;
  transition: all 0.5s ease;
  border-bottom: 1px solid transparent;

  @include bp(navbar-breakpoint) {
    padding: 0 0 0 20px;
    min-height: 80px;
    overflow: inherit;

    &.mini {
      padding: 0 20px;
    }
  }

  &.navbar_open {
    position: fixed;
    top: 0;
    overflow: inherit;
    z-index: 4;
  }

  &.sticky-nav {
    position: sticky; // don't use fixed here it causes jumps on the elemnt below the nav and CLS issue's
    top: 0;
    z-index: 4;
    border-bottom-color: var(--bright-gray);
    box-shadow: 6px 9px 26px 0px rgb(7 8 31 / 6%);
  }

  .container {
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 20px;

    .row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-left: -20px;
      margin-right: -20px;

      .rich-text {
        font-size: 13px;
        line-height: 140%;
      }
    }
  }

  &:not(.mini) .container .row {

    // @include bp(md-lg) {
    @include bp(navbar-breakpoint) {
      justify-content: flex-start;
    }
  }

  // Nav Bar logo
  .navbar-brand {
    margin-right: 20px;
    width: 100%;
    max-width: 105px;

    &:hover {
      background: none;
    }

    &[href=""] {
      cursor: default;
    }

    @include bp(navbar-breakpoint) {
      max-width: 124px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &.mini {
    .navbar-brand {
      max-width: 165px;
      width: auto;

      @include bp(sm) {
        max-width: 180px;
      }

      @include bp(mobile) {
        max-width: 353px;
      }
    }
  }

  // Nav Main toggle Styling
  #nav-toggle {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: none;

    @include bp(navbar-breakpoint) {
      display: none;
    }

    span {
      content: "";
      border-radius: 20px;
      height: 2px;
      width: 24px;
      background: var(--qkn-blurple);
      position: absolute;
      display: block;
      transition: all 100ms ease-in-out;

      &:before {
        content: "";
        border-radius: 20px;
        height: 2px;
        width: 24px;
        background: var(--qkn-blurple);
        position: absolute;
        display: block;
        transition: all 300ms ease-in-out;
        top: -6px;
      }

      &:after {
        content: "";
        border-radius: 20px;
        height: 2px;
        width: 24px;
        background: var(--qkn-blurple);
        position: absolute;
        display: block;
        transition: all 300ms ease-in-out;
        bottom: -6px;
      }
    }

    &.active {
      span {
        background: transparent;

        &:before {
          transform: rotate(45deg);
          top: 0;
        }

        &:after {
          transform: rotate(-45deg);
          top: 0;
        }
      }
    }
  }

  // Nav Bar Collapse Styling
  .navbar-collapse {
    position: relative;
    width: 100%;
    max-width: calc(100% - 174px);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include bp(navbar-breakpoint, max) {
      position: absolute;
      top: 100%;
      left: 100%;
      width: 100%;
      background: var(--white);
      height: calc(100dvh - 60px);
      overflow: auto;
      transition: all 300ms ease-in-out;
      max-width: 100%;
      align-items: flex-start;
    }

    @include bp(navbar-breakpoint) {
      flex-wrap: nowrap;
    }

    &.open {
      left: 0;
    }
  }

  // Nav Bar Menu Styling
  .nav-side-menu,
  .navbar-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    @include bp(navbar-breakpoint) {
      width: auto;
      flex-wrap: nowrap;
    }

    .nav-item {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      border: 0;

      @include bp(navbar-breakpoint) {
        width: auto;
        justify-content: center;
        min-height: 80px;
      }

      @include bp(navbar-breakpoint) {

        &:focus,
        &:focus-within,
        &:hover {
          .nav-link {
            background-color: var(--card-gray);

            &.dropdown-toggle {
              &:after {
                background-image: url("/assets/images/active-chevron-down.svg");
              }
            }
          }

          .dropdown-menu {
            visibility: visible;
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      &.open {
        .nav-link {
          background-color: var(--card-gray);
        }

        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          left: 0;
        }
      }

      .nav-link {
        font-size: 19px;
        line-height: 22px;
        color: var(--eerie-black);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px 12px 9px 16px;
        text-decoration: none;
        background-color: transparent;
        border-radius: 400px;
        white-space: nowrap;

        // @include bp(md-lg, max) {
        @include bp(navbar-breakpoint, max) {
          font-size: 19px;
          line-height: 26px;
          font-weight: 600;
          min-height: 64px;
          width: 100%;
          border-top: 1px solid var(--bright-gray);
          border-radius: 0px;
          justify-content: space-between;
          padding: 10px 20px;
        }

        &.dropdown-toggle {
          position: relative;

          &:after {
            content: "";
            background-image: url("/assets/images/chevron-down.svg");
            background-size: auto;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            color: var(--eerie-black);
            display: block;
            margin-left: 4px;
            width: 13px;
            height: 13px;

            @include bp(navbar-breakpoint, max) {
              background-image: url("/assets/images/purple-left-chevron.svg");
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  // Navbar Menu DropDown Styling With Responsive
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--white);
    border: 1px solid var(--qkn-medium-gray4);
    box-shadow: 6px 9px 26px 0px rgb(7 8 31 / 6%);
    border-radius: $border-radius-default;
    padding: 16px 16px;
    transition: all 0.3s ease;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    min-width: 324px;
    z-index: 4;

    @include bp(navbar-breakpoint, max) {
      position: fixed;
      top: 0;
      transform: translateY(60px);
      padding: 16px 32px;
      padding: 0px;
      min-width: auto;
      width: 100%;
      border: 0;
      box-shadow: none;
      border-radius: 0px;
      left: 100%;
      z-index: 99;
      height: 100%;
      max-height: calc(100% - 60px);
      overflow: auto;
    }

    &.group-menu {

      padding: 0px;

      @include bp(navbar-breakpoint) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 64px;
        padding: 24px 32px;
        min-width: 705px;
      }


      .menu-title {
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        color: var(--qkn-blurple2);
        text-transform: uppercase;
        padding: 9px 16px;

        @include bp(navbar-breakpoint, max) {
          padding: 9px 20px;
        }
      }

      .menu-nav-head {
        margin-bottom: 24px;
      }
    }

    &.sign-in-menu {
      width: 970px;
      padding: 32px;
      left: auto;
      right: 0;

      @include bp(navbar-breakpoint, max) {
        padding: 0px;
        min-width: auto;
      }
    }

    .menu-nav-head {
      display: flex;
      align-items: center;
      font-size: 19px;
      line-height: 26px;
      font-weight: 600;
      color: var(--eerie-black);
      min-height: 64px;
      width: 100%;
      background: var(--card-gray);
      border-radius: 0px;
      padding: 10px 20px;
      border-width: 1px 0px 1px;
      border-style: solid;
      border-color: var(--bright-gray);

      @include bp(navbar-breakpoint) {
        display: none;
      }

      &:before {
        content: "";
        background-image: url(/assets/images/purple-left-chevron.svg);
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        margin-right: 10px;
        width: 24px;
        height: 24px;
        transform: rotateZ(180deg);
      }
    }

    .dropdown-menu-list {
      .dropdown-item {

        @include bp(navbar-breakpoint) {
          border-bottom: 1px solid #e9e9e9;

          &:focus-visible,
          &:focus-within,
          &:hover {
            background-color: var(--card-gray);
            border-radius: 4px;
          }
        }

        @include bp(navbar-breakpoint) {
          padding: 0px 16px;
          border-bottom: 0px;
        }

        &:last-child {
          .menu-list {
            // border-bottom: 0;
            border-bottom: 1px solid #e9e9e9;
          }
        }

        .dropdown-link {
          font-size: 17px;
          line-height: 22px;
          font-weight: 400;
          color: var(--eerie-black);
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 5px 0px;
          min-height: 48px;
          transition: all 0.3s ease;
          border-bottom: 1px solid #e9e9e9;

          &:hover {
            background: none;
          }

          @include bp(navbar-breakpoint, max) {
            padding: 5px 20px;
            min-height: 64px;
          }

          &.menu-list-toggle {
            position: relative;

            .mlt-img {
              height: 24px;
              width: 24px;
            }

            &:after {
              content: "";
              position: absolute;
              top: auto;
              right: 0;
              width: 16px;
              height: 16px;
              background: url(/assets/images/plus-icon.svg), center / contain,
                no-repeat;

              @include bp(navbar-breakpoint, max) {
                right: 20px;
              }
            }
          }
        }

        &:last-child {
          .dropdown-link {
            border-bottom: 0px;
          }
        }

        &.open {

          .menu-list-toggle {
            background: none;

            &:after {
              background: url(/assets/images/minus-icon.svg), center/contain,
                no-repeat;
            }

            border-bottom: 0px;
          }

          .menu-list {
            display: block;
          }

          &:focus,
          &:focus-within,
          &:hover {
            background-color: transparent;
          }

        }

        .menu-list {
          padding: 15px 0;
          border-bottom: 1px solid #e9e9e9;
          display: none;
          transition: all 0.3s ease;

          .list-item {
            margin-bottom: 4px;

            @include bp(navbar-breakpoint, max) {
              padding: 0 20px;
            }

            .list-item-link {
              font-size: 17px;
              line-height: 22px;
              color: rgb(24 24 31 / 80%);
              display: inline-block;
              text-decoration: none;
              padding: 2px 5px;

              &:hover {
                background: none;
              }
            }

            &:last-child {
              margin-bottom: 0px;
            }

            &:focus-visible,
            &:focus-within,
            &:hover {
              background-color: var(--card-gray);
              border-radius: 4px;
            }
          }
        }

        &:last-child {
          border-bottom: 0;

          @include bp(navbar-breakpoint) {
            .dropdown-link {
              border-bottom: 0;
            }
          }
        }
      }


    }

    .dropdown-list {
      @include bp(sm-md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
      }

      @include bp(navbar-breakpoint) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 64px;
      }

      .dropdown-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid var(--bright-gray);
        padding: 20px;

        @include bp(navbar-breakpoint) {
          border-bottom: 0;
          flex-wrap: wrap;
          padding: 0px;
        }

        a {
          &:hover {
            background: none;
          }
        }

        .wrapper {
          width: 100%;
          max-width: 230px;

          @include bp(navbar-breakpoint) {
            max-width: 100%;
            margin-bottom: 24px;
          }

          .content-wrap {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 20px;

            @include bp(navbar-breakpoint) {
              gap: 32px;
              flex-wrap: wrap;
              margin-bottom: 8px;
            }

            img {
              width: 40px;
              height: 40px;

              @include bp(navbar-breakpoint) {
                width: 56px;
                height: 56px;
              }
            }

            .title {
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              color: var(--qkn-blurple2);

              @include bp(navbar-breakpoint) {
                width: 100%;
                font-size: 26px;
                line-height: 100%;
                color: var(--eerie-black);
              }
            }
          }

          .text {
            font-size: 17px;
            line-height: 22px;
            font-weight: 400;
            color: var(--qkn-light-gray);
          }
        }

        .cta-btn {
          margin-top: auto;
        }
      }
    }

    .dropdown-footer {
      display: none;

      @include bp(navbar-breakpoint) {
        display: block;
        font-size: 16px;
        line-height: 20px;
        color: var(--qkn-light-gray);
        border-top: 1px solid var(--bright-gray);
        margin-top: 32px;
        padding-top: 32px;
      }

      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: var(--qkn-blurple);
        text-decoration: none;
        margin-right: 10px;

        img {
          margin-right: 5px;
          vertical-align: middle;
          height: 14px;
          width: 14px;
        }
      }
    }
  }

  // Navbar Side Menu Styling
  .nav-side-menu {
    width: 100%;
    padding: 10px 20px 40px;
    display: flex;
    align-self: flex-end;
    flex-wrap: wrap-reverse;

    @include bp(navbar-breakpoint) {
      padding: 0;
      width: auto;
      flex-wrap: nowrap;
    }

    .nav-item {
      margin-bottom: 12px;
      width: 100%;

      &:first-child {
        margin-bottom: 0px;
      }

      @include bp(navbar-breakpoint) {
        min-height: 80px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        margin-right: 12px;
        width: auto;

        &:last-child {
          margin-right: 0px;
        }

        &:focus,
        &:focus-within,
        &:hover {
          .nav-link {
            background-color: var(--card-gray);

            &.dropdown-toggle {
              &:after {
                background-image: url("/assets/images/active-chevron-down.svg");
              }
            }
          }

          .dropdown-menu {
            visibility: visible;
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }

      &.open {
        .nav-link {
          background-color: var(--card-gray);
        }

        .dropdown-menu {
          visibility: visible;
          opacity: 1;
        }
      }

      .nav-link {
        position: relative;
        font-size: 20px;
        line-height: 22px;
        font-weight: 600;
        color: var(--qkn-blurple);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1.5px solid var(--qkn-medium-gray2);
        border-radius: 400px;
        padding: 20px 32px;
        text-decoration: none;
        transition: all 0.3s ease;

        @include bp(navbar-breakpoint) {
          font-size: 19px;
          line-height: 22px;
          font-weight: 400;
          color: var(--eerie-black);
          padding: 11px 20px 11px 20px;
          border: 0;
        }

        &.user-logged-in {
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex-wrap: nowrap;
            width: auto;
            max-width: 100%;
            display: block;

            @include bp(navbar-breakpoint, min) {
              max-width: 73px;
            }

            @include bp(md-lg, min) {
              max-width: 140px;
            }
          }
        }

        &:not(.user-logged-in) {
          >img {
            display: none;
          }
        }

        &.user-logged-in {
          >img {
            display: block;
            height: 18px;
            width: 18px;

            @include bp(navbar-breakpoint, max) {
              display: none;
            }
          }
        }

        &.dropdown-toggle {
          &:after {
            content: "";
            background-image: url(/assets/images/purple-left-chevron.svg);
            width: 20px;
            height: 20px;
            background-size: auto;
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            color: var(--eerie-black);
            display: inline-block;
            margin-left: 15px;
            vertical-align: middle;

            @include bp(navbar-breakpoint) {
              background-image: url(/assets/images/chevron-down.svg);
              margin-left: 4px;
              width: 13px;
              height: 13px;
            }
          }
        }
      }

      >.cta-btn {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;

        a {
          padding: 20px 32px;
        }

        @include bp(navbar-breakpoint) {
          width: auto;
          font-size: 17px;

          a {
            padding: 12px 24px;
          }
        }
      }
    }
  }

  &:not(.legacy) {
    padding-bottom: 0;

    .badge {
      display: inline-block;
      border-radius: 16px;
      font-size: 13px;
      padding: 0 8px;
      height: 19px;
      line-height: 18px;
      white-space: nowrap;

      &.red-theme {
        background-color: var(--qkn-strike-red);
        color: var(--white);
      }

      &.green-theme {
        background-color: var(--weird-neon-green);
        color: var(--qkn-blurple);
        border: 1px solid var(--dark-neon-green);
      }
    }

    .navbar-collapse {
      position: static;

      @include bp(navbar-breakpoint, max) {
        position: absolute;
      }
    }

    .nav-side-menu .nav-item,
    .navbar-nav .nav-item {
      position: static;

      .dropdown-menu {
        .menu-nav-head {
          margin: 0;
        }

        ul.labels {
          overflow: auto;
          width: 100%;
          white-space: nowrap;
          padding: 24px;

          &>li {
            font-size: 13px;
            font-weight: 600;
            line-height: 1.4em;
            display: inline-block;
            padding: 0.5em;
            margin-right: 12px;
            border-radius: 16px;
            border: 2px solid transparent;
            cursor: pointer;
            position: relative;
            color: var(--qkn-blurple);

            &.active {
              background-color: var(--light-purple2);
            }

            &:focus-visible {
              border-color: #005FCC;
              background-color: var(--white);
            }

            .badge {
              position: absolute;
              font-size: 11px;
              top: -1em;
              left: 60%;
            }
          }

          @include bp(navbar-breakpoint) {
            &>li {
              line-height: 67px;
              float: none;
              border-radius: 0;
              background-color: transparent;

              &.active:not(.columns) {
                background-color: var(--qkn-mid-blue-background);
              }
            }
          }
        }

        ul.contents>li {
          display: none;

          &.visible {
            display: grid;
          }

          ul.columns>li>ul.items {
            &>li {
              border-top: 1px solid var(--qkn-medium-gray2);
              padding: 8px;
              cursor: default;

              &.link {
                cursor: pointer;
                border: 2px solid transparent;

                &:hover {
                  background: var(--card-gray);
                }

                &:focus-visible {
                  border: 2px solid #005FCC;
                }
              }
            }

            .platform-icons {
              display: flex;
              flex-wrap: wrap;
              gap: 12px;

              .platform-contain {
                display: flex;
                align-items: center;
                gap: 10px;

                .windows-icon,
                .world-icon,
                .apple-icon,
                .mobile-icon {
                  height: 18px;
                  width: 18px;
                }
              }
            }

            &>li.item {
              display: grid;
              grid-template-columns: 23px auto;
              grid-template-rows: auto auto;
              gap: 8px 12px;
              grid-auto-flow: column;
              grid-template-areas:
                "icon title"
                ". description"
                ". platform";
              line-height: 21px;

              .platform-icons {
                grid-area: platform;
              }

              &.image:not(.description):not(.heading),
              &.heading:not(.image):not(.description),
              &.description:not(.image):not(.heading) {
                grid-template-columns: auto;
                grid-template-rows: auto;

                &.image {
                  grid-template-areas:
                    "icon";
                }

                &.heading {
                  grid-template-areas:
                    "title";
                }

                &.description {
                  grid-template-areas:
                    "description";
                }
              }

              // Image description heading
              &.image.description.heading {
                grid-template-areas:
                  "icon title"
                  ". description"
                  ". platform";
              }

              // Image heading
              &.image.heading:not(.description) {
                grid-template-areas:
                  "icon title";
                grid-template-rows: auto;
              }

              // Image description
              &.image.description:not(.heading) {
                grid-template-areas:
                  "icon description";
                grid-template-rows: auto;
              }

              // Description heading
              &.description.heading:not(.image) {
                grid-template-columns: auto;
                grid-template-areas:
                  "title"
                  "description";
              }

              &>span {
                align-self: center;
                color: #000;

                &.heading {
                  grid-area: title;
                  color: #000;
                  font-size: 17px;

                  &>p {
                    display: inline;
                  }
                }

                &.description {
                  grid-area: description;
                  color: var(--qkn-light-gray);
                  font-size: 14px;
                }

                &.icon {
                  grid-area: icon;

                  img {
                    display: block;
                    height: 23px;
                    width: 23px;
                  }
                }
              }
            }

            &>li.promo {
              &>div {
                border-radius: 16px;
                background-color: var(--card-gray);
                padding: 12px;

                &:hover {
                  background-color: var(--light-steel-blue);
                }

                .heading {
                  margin-bottom: 8px;

                  p {
                    font-size: 17px;
                    font-weight: 400;
                    display: inline-block;
                  }

                  .badge {
                    margin-left: 8px;
                  }
                }

                .description {
                  font-size: 14px;
                  line-height: 1.5em;
                  color: var(--qkn-light-gray);
                  margin-bottom: 16px;
                }

                img {
                  border-radius: 8px;
                }
              }
            }

            &>li.highlight {
              display: grid;
              grid-template-columns: min-content auto;
              grid-template-rows: auto;
              gap: 0 12px;
              grid-auto-flow: row;
              grid-template-areas:
                "image contents";

              .image {
                height: 49px;
                width: 49px;
                grid-area: image;
              }

              .contents {
                display: grid;
                grid-template-columns: auto;
                grid-auto-flow: row;
                grid-area: contents;
                grid-template-rows: 1fr;
                gap: 0;
                grid-template-areas:
                  "headline";

                .description {
                  display: none;
                  font-size: 16px;
                  line-height: 22.4px;
                  color: var(--qkn-light-gray);
                  grid-area: description;
                }

                .headline {
                  position: relative;
                  font-size: 26px;
                  line-height: 31.2px;
                  grid-area: headline;
                  padding-right: 0.75em;

                  .badge {
                    position: absolute;
                    top: -17px;
                    right: 0;
                  }
                }
              }

              &.description .contents {
                grid-template-rows: 0.65fr 0.35fr;
                gap: 8px 0px;
                grid-template-areas:
                  "headline"
                  "description";

                .description {
                  display: block;
                }
              }

              &.link {
                .contents .headline {
                  background: url('/assets/images/purple-left-chevron.svg') no-repeat;
                  background-position: top 7px right .15em;
                }
              }
            }
          }

          ul.links {
            padding: 28px 0 0 0;
            border-top: 1px solid var(--bright-gray);
            display: none;

            &:has(> li:not(.sign-out)) {
              display: block;
            }

            li {
              display: inline-block;
              font-size: 17px;
              font-weight: 400;
              color: var(--darkGray);
              margin-right: 24px;
              padding: 8px;

              a {
                text-decoration: none;
                position: relative;
                top: -1px;

                &:hover {
                  background: transparent;
                }
              }

              &:hover {
                border-radius: 16px;
                background-color: var(--card-gray);
                cursor: pointer;
              }

              &:focus-visible {
                border: 2px solid #005FCC;
                border-radius: 6px;
              }

              &.sign-out {
                display: none;
              }

              img {
                position: relative;
                top: 3px;
                width: 20px;
                height: auto;
                display: inline-block;
                margin-right: 8px;
              }

              a.cta-link {
                &.eerie-black {
                  color: var(--eerie-black);
                }
              }
            }
          }
        }
      }

      &.user-logged-in {
        .nav-link {
          padding-left: 44px;
          background-image: url(/assets/images/icon-account.svg);
          background-repeat: no-repeat;
          background-position: 20px center;
        }

        .dropdown-menu ul.contents>li ul.links {
          display: block;

          li.sign-out {
            display: inline-block;
          }
        }
      }
    }

    .row:has(ul.secondary) {
      border-bottom: 1px solid rgba(217, 217, 217, 0.30);
      box-shadow: 0px 6px 7px -2px rgba(0,0,0,.15);
      margin: 0 -40px;
      padding: 9px 20px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "indicator primary"
        "list list";

      & > ul.primary {
        grid-area: primary;
      }

      $collapsiblePanelPadding: 22px;
      & > .collapsible-panel {
        grid-area: list;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.5s ease-out;

        ul.secondary {
          display: grid;
          overflow: hidden;
          padding: 0;
          transition: all 0.5s;

          li {
            a {
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: var(--qkn-light-gray);
              font-size: 16px;
              padding: calc($collapsiblePanelPadding / 2) 0;
              border-radius: 6px;
              border: 2px solid transparent;

              &:hover {
                text-decoration: underline;
              }
              &:focus-visible {
                border-color: #005FCC;
              }
            }

            &.active a {
              color: var(--qkn-light-gray);
              opacity: 0.5;
            }
          }
        }
      }

      &.open > .collapsible-panel {
        grid-template-rows: 1fr;

        ul.secondary {
          padding: calc($collapsiblePanelPadding / 2) 0;
        }
      }

      .indicator {
        grid-area: indicator;
        color: var(--qkn-blurple);
        text-decoration: underline;
        cursor: pointer;

        span {
          display: inline-block;
          text-decoration: underline;
          padding-right: 30px;
          background: url('/assets/images/chevron-14x7-down.svg') no-repeat right 5px center;
        }
      }

      &.open .indicator span {
        background-image: url('/assets/images/chevron-14x7-up.svg');
      }
    }

    $transitionDuration: 0.5s;
    transition: min-height $transitionDuration ease, padding $transitionDuration ease;
    .container {
      .row:nth-child(1) {
        transition: max-height $transitionDuration ease;
        height: 80px;
      }
    }

    &.compact {
      min-height: 0;
      padding-top: 0;

      .container {
        .row:nth-child(1) {
          overflow: hidden;
          max-height: 0;
        }
      }
    }

    @include bp(navbar-breakpoint) {
      .row:has(ul.secondary) {
        box-shadow: none;
        padding: 0 60px 0 0;
        border-bottom: 0;
        border-top: 1px solid var(--qkn-medium-gray2);
        margin: 0 -20px;
        grid-template-rows: 1fr;
        grid-template-areas:
          "list primary";
        height: 80px;

        .indicator {
          display: none;
        }

        & > .collapsible-panel {
          display: block;

          ul.secondary {
            display: block;

            li {
              float: left;
              margin-right: 24px;
              height: 100%;

              a {
                line-height: 100%;
                padding: 12px;
              }

              &.active a {
                color: var(--qkn-blurple);
                opacity: 1;
                text-decoration: underline;

                &:hover {
                  cursor: default;
                }
              }
            }
          }
        }
      }

      .nav-side-menu .nav-item,
      .navbar-nav .nav-item {
        $arrowSize: 20px;
        position: relative;
        background: white;

        &:has(> div.dropdown-menu) {
          &::after {
            background: var(--white);
            height: $arrowSize;
            width: $arrowSize;
            position: absolute;
            left: 50%;
            bottom: calc($arrowSize / -1);
            z-index: 11;
            transform: rotate(45deg);
            border: 1px solid var(--qkn-medium-gray4);
            border-right: 0;
            border-bottom: 0;
            margin-left: calc($arrowSize / -2);
            display: none;
            content: ' ';
          }

          &:hover::after {
            display: block;
            transition: bottom 1s ease 0s;
            bottom: calc($arrowSize / -2);
          }
        }

        .dropdown-menu,
        .dropdown-menu.group-menu {
          z-index: -1;
          overflow: hidden;
          height: 0;
          padding: 0;
          display: inline-block;
          min-width: auto;
          left: auto;
          right: auto;
          /*
            // QWEB-272
            transform: translateX(set-by-javascript);
          */
        }

        &:hover .dropdown-menu,
        &:focus-within .dropdown-menu,
        &:focus .dropdown-menu {
          z-index: 10;
          height: auto;
        }

        .dropdown-menu {
          &>div {
            display: grid;
            grid-template-columns: 0 auto;
            grid-template-rows: auto;
            grid-auto-flow: row;
            grid-template-areas:
              "left right";

            &.panels {
              grid-template-columns: 345px auto;
              gap: 0;
            }

            &>ul.labels {
              grid-area: left;
              border-right: 1px solid var(--qkn-medium-gray4);
              width: inherit;
              padding: 0;
              padding-top: 33px;
              overflow: visible;
              white-space: normal;
              cursor: default;

              &>li {
                display: block;
                font-size: 21px;
                font-weight: 400;
                border: 0;
                color: var(--qkn-light-gray);
                padding: 0 0 0 20px;
                background: url('/assets/images/purple-left-chevron.svg') no-repeat;
                background-position: center right 1em;
                margin: 0;

                &.active {
                  color: var(--qkn-blurple);
                  background-color: var(--light-steel-blue);
                }

                .badge {
                  font-size: 13px;
                  position: static;
                  top: auto;
                  left: auto;
                }
              }
            }

            &>ul.contents {
              display: grid;
              grid-area: right;

              &>li {
                visibility: hidden;
                display: grid;
                grid-template-rows: auto;
                gap: 28px 0;
                grid-area: 1 / 1;
                padding: 32px;

                &.visible {
                  visibility: visible;
                }

                &>ul.columns {
                  display: grid;
                  grid-template-columns: repeat(auto-fit, 316px);
                  grid-template-rows: auto;
                  gap: 0px 32px;
                  grid-auto-flow: row;

                  &[data-column-count="1"] {
                    grid-template-areas:
                      ".";
                  }

                  &[data-column-count="2"] {
                    grid-template-areas:
                      ". .";
                  }

                  &[data-column-count="3"] {
                    grid-template-areas:
                      ". . .";
                  }

                  &>li {
                    width: 316px;

                    &>ul.items {
                      display: grid;
                      grid-template-columns: auto;
                      grid-template-rows: auto;
                      gap: 24px 0px;
                      grid-auto-flow: row;

                      &>li {
                        border-radius: 16px;
                        border-top-width: 0;

                        &.link:focus-visible {
                          border-radius: 6px;
                        }

                        &.item:focus-visible {
                          border-top-width: 2px;
                        }

                        &.promo:hover,
                        &.promo:focus-visible {
                          background: transparent;
                        }
                      }
                    }
                  }
                }

                &:has(> ul.links) {
                  grid-template-rows: auto min-content;
                }
              }
            }
          }
        }
      }

      .nav-side-menu .nav-item .dropdown-menu {
        left: inherit;
        right: 0;
      }
    }
  }
}