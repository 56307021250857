footer {
  padding: 40px 20px 0;

  @include bp(md) {
    padding: 40px 148px 0;
  }

  .top {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    justify-content: space-between;
    border-top: 1px solid var(--qkn-medium-gray2);
    padding-top: 64px;

    @include bp(sm-md) {
      flex-direction: row;
    }
    .left {
      margin-right: 135px;
      white-space: nowrap;
      img {
        width: 213px;
        height: 41px;
        display: block;
        @include bp(md, max) {
          width: 124px;
          height: 24px;
        }
      }
      .social-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 41px;
        height: 40px;
        margin-top: 40px;
        margin-right: 15px;
        background-color: var(--qkn-light-blue);
        border-radius: 20px;
        @include bp(sm, max) {
          margin-bottom: 20px;
        }
        @include bp(md, max) {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        &:hover svg {
          .social-svg {
            fill: var(--qkn-light-blue);
          }
        }
        &:hover {
         background-color: var(--qkn-blurple);
        }
      }
    }

    .right {
      .footer-menu {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        margin: auto;
        max-width: 680px;

        &.columns-3,
        &.columns-2 {
          @include bp(mobile) {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        &.columns-3 {
          @include bp(sm-md) {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        .menu {
          padding-top: 22px;
          line-height: 22px;
          min-width: 145px;

          @include bp(sm-md) {
            padding-top: 0px;
          }

          span {
            display: inline-block;
            font-weight: 600;
            padding-bottom: 16px;
          }
          ul {
            padding-bottom: 30px;
            li {
              padding-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .middle {
    border-top: 1px solid var(--qkn-medium-gray2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include bp(md, max) {
      flex-direction: column-reverse;
    }
    .left {
      display: flex;
      flex-direction: column;
      width: 50%;
      @include bp(md, max) {
        width: auto;
      }
      .footnotes-toggle {
        &:hover {
          cursor: pointer;
        }
        padding-bottom: 20px;
      }
      .do-not-sell-link {
        padding-bottom: 20px;
      }
      .promo-disclaimer-text,
      .footnotes-toggle,
      .disclaimer-text {
        font-size: 13px;
      }
      .promo-disclaimer-text {
        padding-top: 40px;
        p {
          line-height: 150%;
          margin-bottom: 10px;
        }


      }
      .disclaimer-text {
        padding-top: 30px;
        padding-bottom: 40px;
        p {
          line-height: 150%;
          margin-bottom: 10px;
        }
      }
      .footnotes-text {
        height: 0;
        overflow: hidden;
        transition:  height 1s ease-out;
        margin-bottom: 20px;
        font-size: 14px;
        p {
          line-height: 150%;
          margin-bottom: 10px;
        }
      }
    }
    .right {
      padding-top: 30px;
      font-weight: 600;
      .download-title {
        font-weight: 600;;
      }
      .download-icon {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 20px;
        .q-icon {
          margin-right: 50px;
          @include bp(md, max) {
            height: 28px;
            margin-right: 10px;
          }
        }

        a {
          &:not(:last-child) {
            .appstore-icon {
              padding-right: 5px;
            }
          }
        }

        .appstore-icon {
          width: auto;
          height: 28px;

          @include bp(md) {
            width: auto;
            height: 38px;
          }
        }
      }
    }
  }
  .full {
    & > span {
      line-height: 3em;
    }
  }
  .bottom {
    border-top: 1px solid var(--qkn-medium-gray2);
    padding-top: 40px;
    padding-bottom: 64px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    .left {
      color: var(--qkn-light-gray);
      width: 60%;
      @include bp(md, max) {
        width: 100%;
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 40%;
      @include bp(md, max) {
        width: 100%;
        flex-direction: column;
        padding-top: 10px;
      }
      .legal-link {
        padding-left: 30px;
        @include bp(md, max) {
          padding: 10px 30px 10px 0;
        }
      }
    }
  }

  .accordion {
    display: grid;
    grid-template-rows: min-content 0fr;
    transition: grid-template-rows $transition-duration-default;
    margin-bottom: 1em;

    &>dt {
      cursor: pointer;
      color: var(--qkn-medium-gray);
      font-size: 13px;
      line-height: 4em;
      text-decoration: underline;
      background: url('/assets/images/chevron-14x7-down.svg');
      background-repeat: no-repeat;
      background-position: 100% 50%;
    }
    &.open > dt {
      background-image: url('/assets/images/chevron-14x7-up.svg');
    }

    dd {
      overflow: hidden;
      border-bottom: 1px solid var(--qkn-medium-gray2);
      padding: 0;
      transition: padding $transition-duration-default;
      font-size: 13px;
      line-height: 150%;

      ul {
        list-style: disc;
      }
      ol {
        list-style: decimal;
      }
      ul, ol {
        li {
          margin-left: 2em;
        }
      }

      a:hover {
        background: var(--qkn-light-blurple);
      }

      &>p {
        margin: 1em 0;
      }
    }

    &.open {
      grid-template-rows: min-content 1fr;

      dd {
        padding: 2em 0;
      }
    }
  }
}