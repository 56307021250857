
.search-results-wrapper {
  padding: 21px; 
  
  .search-heading-wrap {
    display: flex;

    flex-direction: column;
    @include bp(md-lg) {
      flex-direction: row;
    }

    justify-content: space-between;
    margin-top: 21px; 

    .search-crumb-container {
      .search-crumb {
        a {
          color: var(--qkn-blurple); 
        }
      }
    }

    .search-heading-gpt {
      display: flex;
      justify-content: center; 
      @include bp(mobile) {
        justify-content: space-between;
      }
      align-items: center;

      border: solid var(--gray-ee) 1px;
      border-radius: 22px; 
      padding: 21px; 
      background: var(--card-gray); 

      .gpt-text {
        display: none; 
        @include bp(mobile) {
          display: block; 
        }
      }

      strong {
        color: var(--qkn-blurple);
        font-weight: 600;
        margin-right: 8px; 
      }

      .search-gpt-button {
        display: flex;
        height: 40px;
        padding: 5px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 38px;
        background: var(--base-sky-600, #7AE7FB);
        color: var(--qkn-blurple); 
        font-weight: 600;

        margin-left: initial;  
        @include bp(mobile) {
          margin-left: 13px; 
        }
        
        width: 250px; 
      }

    }

    .search-area-heading {
      margin: 13px 0; 
      font-size: 48px; 
      color: var(--qkn-medium-gray); 
    }
  }

  .search-area {
    margin: 0 auto;
    padding: 21px 8px;
    display: flex; 

    flex-direction: column;
    @include bp(navbar-breakpoint) {
      flex-direction: row;
    }
    justify-content: space-between;
  
    .search-area-heading {
      font-size: 32px;
      padding-bottom: 13px;
      border-bottom: solid #eeeeef 4px;
      letter-spacing: -0.3px;
    }
  
    .search-results-filters-area {
      flex-basis: 20%; 
      margin-top: 13px; 

      .search-fils-header {
        border-top: solid var(--qkn-medium-gray2) 1px; 
        background: var(--card-gray); 
        border-radius: 16px 16px 0 0;
        color: var(--qkn-blurple); 
        font-weight: 600; 
        line-height: 150%; 
        font-size: 14px; 
        padding: 13px 24px;
      }

      .faceted-search-panel {
        border: solid var(--qkn-medium-gray2) 1px; 
        border-top: none; 
        padding: 24px 32px 32px 32px; 
        border-radius: 0 0 16px 16px;
        box-shadow: 6px 9px 26px 0px rgba(7, 8, 31, 0.06);

        .help-source-fil-section {
          padding: 8px 0 8px 0; 
          @include bp(navbar-breakpoint) {
            padding: 8px 0 50px 0;
          }
  
          .search-fil-heading {
            font-size: 16px; 
            color: var(--qkn-light-gray); 
            border-bottom: solid #eaecf7 1px; 
            padding-bottom: 8px; 
          }
  
          .item-list {
            ul {
              li {
                color: var(--qkn-light-gray); 
                font-size: 16px; 

                // Make the checkbox blurple. 
                input {
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  border: 1.5px solid var(--qkn-light-gray);
                  opacity: 0.5;
                }
                
                input[type="checkbox"]:checked {
                  width: 16px;
                  height: 16px;
                  border: 1px solid var(--qkn-blurple);
                  border-radius: 2px;
                  cursor: pointer;
                  position: relative;
                  accent-color: var(--qkn-blurple);
                  opacity: 1.0;
                }

              }
            }
  
          }
        }
      }

    }
 
    .search-results-area-wrap {
      position: relative; 
      flex-basis: 85%;
 
      .please-wait-msg {
        display: flex; 
        flex-direction: row; 
        align-items: center; 
        position: absolute;   
        top: -16px; 
        left: 175px; 
        width: 100%; 

        &.agent-home-wait-msg {
          top: -3px;
          left: 13px; 
        }

        &.mobile-mode {
          font-size: .8rem;
          top: -27px; 
          left: 45%; 
          
          .please-wait-img {
            height: 21px; 
          }
        }

        // Support agent home in mobile mode
        &.agent-mobile-mode {
          font-size: .8rem;
          top: -3px;
          left: 17px;

          .please-wait-img {
            height: 21px; 
          }
        }

        p {
          margin-left: 5px; 
        }
      }
   
      // Pager  
      .search-paging {
        .search-pages {
          display: inline;
          
          .search-pager-item {
            font-size: 21px;
            font-weight: bold; 
            padding: 13px;
            display: inline-block;
            border-radius: 8px; 
            border: solid 1px var(--bright-gray);
            width: 50px;
            height: 45px;
            text-align: center;
            margin-right: 3px; 

            a {
              color: var(--qkn-blurple);        
              &.active {
                color: var(--qkn-red);   
              }
            }
 
          }   
        } 
      }
    }
 
    .search-results {
      flex-basis: 70%; 
      
      display: flex;
      flex-direction: column; 
      @include bp(md) {
        flex-direction: row; 
      }

      justify-content: space-evenly; 
      min-height: 400px; 
  
      .search-no-results {
        .search-no-results-msg {
          margin-top: 21px;
          font-size: 21px;
        }

        a {
          color: var(--qkn-blurple); 
        }
      }
 
      .search-result-col {
        padding: 13px 0 13px 0; 
        @include bp(navbar-breakpoint) {
          padding: 13px 0 13px 13px;  
        }

        &.search-results-pages {
          &.basis-65 {
            flex-basis: 65%; 
          }
        }

        &.search-results-vids {
          &.basis-35 {
            width: unset; 
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            @include bp(md) {
              display: block; 
              width: 40%; 
            }

            .search-result-item {
              flex-basis: 98%; 
              margin-bottom: 13px;      
              margin-right: unset; 

              @include bp(mobile) {
                flex-basis: 45%; 
                margin-right: 8px;
              }
            }
          }

          .search-result-item {
            width: 100%; 
            margin-bottom: 13px; 
          }

          &.only-videos {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap; 
            justify-content: stretch; 

            .search-result-item {
              margin-right: 8px; 
              flex-basis: 98%; 
              
              @include bp(mobile) {
                flex-basis: 47%; 
              }

              @include bp(lg) {
                flex-basis: 32%; 
              }
            }
          }
        }

        .search-result-item {
          display: flex;
          flex-direction: column;
 
          border: solid 1px var(--gray-ee);
          border-radius: 24px; 
          padding: 13px;
          margin-bottom: 13px; 

          .yt-thumbnail {
            margin: 0 auto;
        
            img {
              width: 100%;

              @include bp(md) {
                width: 275px;
              }
        
              height: auto;
              border-radius: 21px;
            }
          }
    
          .search-result-heading {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            margin: 13px 0;
    
            .search-result-line-image {
              height: 34px;
              margin-right: 8px;
              padding: 5px;
            }
    
            .search-result-link {
              color: var(--qkn-blurple);
    
              &:visited {
                color: var(--qkn-blurple);
              }
    
              &:active {
                color: var(--qkn-blurple);
              }
              
            }
          }
        } 
      } 
    }
  }
}

.agent-home-page {
  .search-results-wrapper {
    padding: unset; 
    .search-area {
      padding: unset; 
    }
  }
}
