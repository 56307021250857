//
// Optimizely winner from QMNT-6499
// Bottom bar vote. 
//
.pane-feedback.opt-sticky {
  position: sticky;
  bottom: 0px; 
  width: 100%; 
  z-index: 20000;
  
  .feedback-complete-label {
    padding: 15px; 
  }

  #quicken-feedback-widget {
    border: solid 1px var(--gray-dd); 

    &.vote-processed {
      width: 98%;
      position: sticky;
      border: solid var(--gray-ee) 1px;
      background: var(--gray-ee);
      bottom: 15px;
      padding: 11px;
      z-index: 3000;
      text-align: center;
      margin: auto;

      @include bp(sm) {
        width: 450px;
        position: fixed;
        right: 7px;
        border: solid var(--gray-ee) 1px;
        background: var(--gray-ee);
        bottom: 10px;
        padding: 11px;
        z-index: 3000; 
      }

      .quicken-feedback {
        display: none; 
      }
    }

    .quicken-feedback-yes {
      margin-right: 4px; 
      a::before {
        content: unset; 
      }
    }

    .quicken-feedback-no {
      a::before {
        content: unset;
      }
    }

    .clozer {
      width: 100%;
      text-align: right;
      cursor: pointer;
    }

    #qkn-feedback-closer {
      position: absolute; 
      right: 2px;
      top: 0px;
      margin: 5px; 
      background: var(--gray-ee); 
      cursor: pointer; 
      padding: 2px;
      font-weight: bold;
    }

    .quicken-feedback-widget {
      float: unset;
    }

    .quicken-feedback-link {
      width: unset; 

      a {
        display: inline-block;
        position: relative;
        padding: 4px 12px 4px 12px;
        border: 1px solid var(--qkn-blurple);
        border-radius: 6px; 
        font-size: 12px;
        line-height: 1.5;
        font-weight: 600;
        background: var(--qkn-blurple);
        color: var(--white);
        transition: all .3s ease;
        width: 65px;
        margin-right: 4px; 
        text-align: center;
    
        @include bp(sm) {
          display: inline-block;
          position: relative;
          padding: 4px 12px 4px 12px;
          border: 1px solid var(--qkn-blurple2);
          border-radius: 6px; 
          font-size: 12px;
          line-height: 1.5;
          font-weight: 600;
          background: var(--qkn-blurple);
          color: var(--white);
          transition: all .3s ease;
          width: 100px;
          text-align: center;
        }
    
        @include bp(md) {
          display: inline-block;
          position: relative;
          padding: 4px 12px 4px 12px;
          border: 1px solid var(--qkn-blurple2);
          border-radius: 6px; 
          font-size: 12px;
          line-height: 1.5;
          font-weight: 600;
          background: var(--qkn-blurple);
          color: var(--white);
          transition: all .3s ease;
          width: 100px;
          text-align: center;
        }
      }
    }

    .quicken-feedback-comment-submit {
      padding: 13px; 
      .button {
        background: var(--qkn-red);
        color: var(--white);
        margin: 5px;
        border-radius: 20px;
        padding: 12px 17px;
      }
    }

    .quicken-feedback-widget {
      float: unset;
      padding-top: 0px;  
    }
  }

  .quicken-feedback-form {
    border-top: 1px solid var(--gray-dd); 
    background: var(--gray-ee); 
    padding: 13px; 

    .form-wrapper {
      margin-top: 10px;
      display: flex;
      padding: 13px;
      background: var(--white);
      margin: 21px auto;
			
      .support-search__input, .support-search__input:focus {
        outline: none;
        border: none;
        box-shadow: none;
        padding: 0px 0px 0px 32px;
        width: 94%; 
        font-size: 13px; 
        text-align: left;
        height: 40px; 
      }

      .support-search__btn {
        height: 40px;
        &:focus, &:focus-visible {
          outline: none; 
        } 
        
        border-radius: 40px; 
        background: var(--qkn-red);
        border: solid 1px var(--dark-red); 
        color: var(--white); 
        font-weight: 600; 
        padding: 8px 25px;
        font-size: 15px;  
        cursor: pointer; 
      }

      .form-item-q::before {
        padding-bottom: 0; 
        background-repeat: no-repeat;
        z-index: 2; 
      }
    }
  } 

  .quicken-feedback {
    .form-item-quicken-feedback {
      // color: var(--white); 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 

      @include bp(md) {
        // color: var(--white);
        display: flex;
        flex-direction: row;
        justify-content: center;  
      }
  
      .quicken-feedback-widget {
        margin: auto; 
        
        @include bp(sm) {
          text-align: unset;
        }
  
        @include bp(md) {
          display: flex;
          flex-direction: row;
          justify-content: space-around; 
          margin: unset; 
        }
      }
  
      label {
        padding: 11px;  
        text-align: center;
        
        @include bp(md) {
          padding: unset; 
          padding-right: 11px; 
          text-align: unset; 
        }
      }
    }
  }

  .feedback-complete-label {
    display: none; 
  }

  .success-description {
    margin-bottom: 5px;  
    text-align: center;
  }

  #qkn-pane-closer {
    position: absolute;
    right: 15px;
    top: 9px;
    cursor: pointer;
    font-size: 1.2rem;
    z-index: 4000; 
  }

  .success-description::before {
    content: unset; 
  }
}

#genesys-mxg-frame {
  bottom: 60px !important; 
}

/////////////////////////////////

// Feedback

// Entire feedback form container
.quicken-feedback-form {
  &.vote-processed {
    margin: 20px 0;
  }
  
  padding: 12px 24px;
  border-radius: 4px;
  background: var(--gray-ee);
}

// Just the initial prompt for a thumbs up or thumbs down
.quicken-feedback {
  position: relative;
}

.quicken-feedback .form-item {
  display: flex;
  margin: 0;
  flex-direction: column;

  @include bp(sm) {
    display: block;
  }
}

.quicken-feedback label,
.feedback-complete-label { // "Was this article helpful?"
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 600;
  color: var(--gray-22);
  order: 1;
}

.quicken-feedback .description { // "Help us improve our support center"
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.57;
  color: var(--gray-44);
  order: 2;

  @include bp(sm) {
    margin-bottom: 0;
  }
}

.quicken-feedback-widget { // Wrapper for the two buttons
  order: 3;

  @include bp(sm) {
    float: right;
    padding-top: 10px;
  }
}

.quicken-feedback-link { // Each button's wrapper
  float: left;

  & + .quicken-feedback-link {
    margin-left: 4px;
  }
}

.quicken-feedback-link a { // Each button
  display: inline-block;
  position: relative;
  padding: 4px 12px 4px 32px;
  border: solid 1px var(--qkn-blurple2);
  border-radius: 2px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  background: var(--white);
  color: var(--qkn-blurple);
  transition: 0.3s ease all;

  &:hover,
  &:focus {
    background: var(--gray-f3);
    text-decoration: none;
  }

  &:before {
    position: absolute;
    left: 14px;
    margin-top: -1px;
    font-size: 14px;
    font-family: "Font Awesome", sans-serif;
    font-weight: normal;
  }
}

.quicken-feedback-yes a:before {
  content: '\f087';
}

.quicken-feedback-no a:before {
  content: '\f088';
}

.vote-processed .quicken-feedback a,
.vote-processed .quicken-feedback a:hover,
.vote-processed .quicken-feedback a:focus {
  border-color: var(--gray-77);
  background: var(--white);
  color: var(--gray-66);
  cursor: default;
}

.vote-processed .quicken-feedback .selected a,
.vote-processed .quicken-feedback .selected a:hover,
.vote-processed .quicken-feedback .selected a:focus {
  background: var(--qkn-blurple);
  border-color: var(--blue);
  color: var(--white);
}

// When the form is open
.quicken-feedback-form .still-looking-message-wrapper {
  display: none;
  padding: 12px auto; 
  margin-top: 12px;
  border-top: solid 1px var(--gray-dd);
  background: var(--white);
	
  .top-search-wrapper {
    margin-top: 11px; 
  }
}

.quicken-feedback-form .still-looking-message {
  padding: 1rem 4rem;
  background: white;
  font-size: 1.1em;
}

.quicken-feedback-form {
  .form-type-radios {
    label {
      &.helpful-radio-labels {
        color: var(--gray-22);
        font-weight: 600; 
        align-self: center; 
      }

      &.heading-label {
        color: var(--gray-22); 
        font-weight: 600; 
        align-self: center; 
      }
    }

    input {
      align-self: center; 
    }

    .form-radios {
      margin-top: 13px; 
    }

    .form-item {
      display: flex;
      position: relative;
      margin: 0;
      padding: 5px 0 5px 38px;
    }
  }

  .form-type-textarea {
    margin: 13px auto; 
    label {
      &.heading-label {
        color: var(--gray-22); 
        font-weight: 600; 
        align-self: center; 
      }
    }

    .form-textarea-wrapper {
      margin: 13px auto; 
    }
  }

  input[type="radio"] {
    position: absolute;
    left: 11px;
  }

  textarea {
    max-width: 100%;
    padding: 12px 11px;
    border-radius: 4px;
    border: solid 1px var(--gray-cc);
    font-size: 14px;
    line-height: 1.36;
  }
}

.quicken-feedback-comment-submit {
  margin-top: 12px;
  a {
    margin-bottom: 0;
    cursor: pointer;
  }
}

// Adding a checkmark when they click yes
.success-description {
  margin: 4px 0;
}

.ajax-progress-throbber {
  display: none !important; 
}

//
// Optimizely winner from QMNT-6499
// Bottom bar vote. 
//
.pane-feedback.opt-sticky {
  position: sticky;
  bottom: 0px;
  width: 100%;
  // padding: 8px;  
  z-index: 20000;
  
  .feedback-complete-label {
    padding: 15px; 
  }

  .quicken-feedback {
    .form-item-quicken-feedback {
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      @include bp(md) {
        display: flex;
        flex-direction: row;
        justify-content: center;  
      }
  
      .quicken-feedback-widget {
        margin: auto; 
        
        @include bp(sm) {
          text-align: unset;
        }
  
        @include bp(md) {
          display: flex;
          flex-direction: row;
          justify-content: space-around; 
          margin: unset; 
        }
      }
  
      label {
        padding: 11px;  
        text-align: center;
        
        @include bp(md) {
          padding: unset; 
          padding-right: 11px; 
          text-align: unset; 
        }
      }
    }
  }

  .feedback-complete-label {
    display: none; 
  }

  .success-description {
    margin-bottom: 5px;  
    text-align: center;
  }

  #qkn-pane-closer {
    position: absolute;
    right: 15px;
    top: 9px;
    cursor: pointer;
    font-size: 1.2rem;
  }

  .success-description::before {
    content: unset; 
  }
}

.grecaptcha-badge {
  bottom: 125px !important; 
}

.btt-contain {
  bottom: 62px;
}

