// Global css applications and global css classes to be used throughout components
section {
  padding: 24px 0 24px 0;

  @include bp(md) {
    padding: 40px 0 40px 0;
  }
}

main section:first-of-type {
  padding-top: 0px;
}

a.anchor {
  font-size: 0;
}

.content {
  width: 100%;
  display: flex;
  overflow: scroll;
  scroll-behavior: smooth;
  list-style: none;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ta-left {
  text-align: left;
  align-items: left;
  justify-content: left;
}

.ta-center {
  text-align: center;
}

.ta-center {
  text-align: right;
}

// Global padding override classes used within components to 'undo' the normal padding
.pad-btm-0 {
  padding-bottom: 0px !important;
}

.pad-btm-20 {
  padding-bottom: 20px !important;
}

.pad-mobile-btm-12 {
  @media (max-width: 570px) {
    padding-bottom: 12px !important;
  }
}










// This appears to be unused? we should delete but need to verify this
.person-tier {
  text-align: center;
  color: rgb(34, 50, 79);
  font-size: 25px;
  font-weight: bolder;
  padding-top: 40px;
  padding-bottom: 20px;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 12px;
  }
}



// Testimonials, this should be in it's own file
.scrolling-testimonials-wrapper {
  background-color: rgb(228, 234, 246);

  .content-wrapper {
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
    width: 860px;

    @media (max-width: 900px) {
      width: 600px;
    }

    @media (max-width: 700px) {
      width: 400px;
    }

    @media (max-width: 500px) {
      width: 300px;
    }

    .dots-wrapper {
      padding-bottom: 40px;
      margin: 0 auto;
      text-align: center;

      li {
        position: relative;
        display: inline-block;
        padding-left: 50px;

      }

      button {
        display: block;
        padding: 5px;
        color: #5EBFEA;
        cursor: pointer;
        border: 0;
        outline: 0;
        background: 0 0;
        font-size: 80px;
        line-height: 0;
        opacity: .5;

        &::before {
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: '•';
        }

        &.active {
          opacity: 1 !important;
        }
      }

    }
  }
}



.testimonial {
  width: 100%;
  height: 100%;
  flex: 1 0 100%;
}

.stars-5 {
  height: 80px;
  padding-top: 25px;
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/assets/images/5-stars.svg');
}

.quote {
  text-align: center;
  color: rgb(34, 50, 79);
  font-size: 25px;
  line-height: 37px;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 12px;
  }
}

.single-testimonial-wrapper {
  background-color: rgb(47, 62, 168);
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;

  .content-wrapper {
    width: 600px;
    margin: auto;
    background: white;
    border-radius: 0px 60px;
    padding-top: 20px;
    padding-bottom: 20px;

    .logo {
      margin: auto;
      width: 150px;
    }
  }
}





// Cards, this needs to be moved to it's own file not sure wtf this file has become
$margin-horizontal-simple-cards: 15px;
$padding-top-simple-cards: 48px;
$min-width-simple-cards: 250px;
$max-width-simple-cards: none;

.cards-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .cards-wrapper {
    width: 100%; //do not remove to allow heading alignment
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 39px 0px;

    // h3 {
    //   font-family: $fontfamily;
    //   font-size: 16px;
    //   margin-top: 22px;
    //   line-height: 1.35;

    //   @include bp(md) {
    //     font-size: 23px;
    //     margin-top: 33px;
    //     margin-bottom: 47px;
    //   }
    // }

    .cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 17px;
      padding-top: 32px;

      @include bp(md) {
        grid-template-columns: repeat(2, 1fr);
      }

      .simple-card,
      .feature-list-card {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 12px;
        border-radius: $border-radius-default;
        overflow: hidden;
      }

      .simple-card {
        min-width: $min-width-simple-cards;
        max-width: $max-width-simple-cards;
        $padding-horizontal: 43px;
        padding: $padding-top-simple-cards $padding-horizontal 40px;

        &.dark-font {
          color: var(--darkGray);
          .rich-text {
            color: var(--darkGray);
          }
        }

        &.light-font {
          color: var(--white);
          .rich-text {
            color: var(--white);
          }
          a {
            color: var(--white);
            text-decoration: underline;
            &:hover {
              background: var(--light-white);
            }
          }
        }

        &.box-shadow {
          box-shadow: 49px 82px 27px 0px rgba(0, 0, 0, 0.00), 
            32px 52px 24px 0px rgba(0, 0, 0, 0.01),
            18px 30px 21px 0px rgba(0, 0, 0, 0.04),
            8px 13px 15px 0px rgba(0, 0, 0, 0.07), 
            2px 3px 8px 0px rgba(0, 0, 0, 0.08);
        }

        &.bg-image {
          background-size: cover;
          background-repeat: no-repeat;
        }

        .heading {
          text-align: center;
          width: 100%;
          font-size: 24px;
          font-family: $fontfamily;
          font-weight: $font-weight-normal;

          @include bp(md) {
            font-size: 26px;
          }
        }

        img {
          width: calc(100% + ($padding-horizontal * 2));
          margin: 0 calc($padding-horizontal * -1);
          height: auto;
        }

        &>span {
          text-align: center;
          color: var(--qkn-light-gray);
          line-height: 1.35;
          font-size: 16px;
          font-weight: 400;
        }

        &.no-expand {
          padding-left: 0;
          padding-right: 0;

          img {
            padding: 0 $padding-horizontal;
          }
        }

        &.white-background {

          h4,
          span {
            padding-left: 0;
            padding-right: 0;
          }

          img {
            border-radius: 15px;
          }
        }
      }

      .feature-list-card {
        margin: 20px 0px;
        padding-top: 40px;
        max-width: 564px;
        overflow: initial;
        grid-template-rows: 0.25fr .25fr auto;
        position: relative;
        &.has-topper {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          margin-top: 40px;

          @include bp(md) {
            margin-top: 20px;
          }
        }

        .card-topper {
          position: absolute;
          width: 100%;
          font-size: 17px;
          font-weight: 600;
          border-radius: 16px 16px 0px 0px;
          line-height: 140%;
          padding: 8px 40px;
          text-align: left;
          top: -39px;
          background-color: var(--qkn-red-background);
          color: var(--qkn-red);

          // Tried to let them set qkn vs simplifi theme but we don't appear to have a background
          // color that works with blurple text color at the moment
          // &.classic-red-theme {
          //   background-color: var(--qkn-red-background);
          //   color: var(--qkn-red);
          // }
          // &.simplifi-purple-theme {
          //   background-color: var();
          //   color: var(--qkn-blurple);
          // }
        }

        .product-header {
          display: flex;
          flex-direction: column;
          text-align: left;
          padding: 0px 30px;
          gap: 12px;

          @include bp(md) {
            padding: 0px 40px;
          }

          .top {
            display: flex;
            flex-direction: column;
            gap: 12px;

            h3 {
              margin: 0;
            }

            @include bp(md) {
              flex-direction: row;
              gap: 21px;
            }

            .cta-contain {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 15px;

              @include bp(md) {
                flex-direction: column;
                justify-content: center;
              }
            }
          }

          .product-icons {
            display: flex;
            flex-direction: row;

            .platform-contain {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              &:not(:last-child) {
                margin-right: 30px;
              }

              img {
                height: 15px;
                width: 15px;
                margin-right: 9px;
              }
            }
          }

          .price-contain {
            display: flex;
            .price-section {
              &.inline {
                justify-content: flex-start;
              }
            }
          }
        }

        .top-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: start;
          width: 100%;
          padding-left: 40px;
          padding-right: 40px;
          box-sizing: border-box;

          h3 {
            width: 50%;
            text-align: left;
          }

          button {
            font-size: 17px;
          }
        }

        .img-contain {
          padding: 0px 30px;

          @include bp(md) {
            padding: 0px 40px;
          }

          .feature-img {
            width: 100%;
            height: auto;
          }
        }

        .feature-contain {
          display: flex;
          flex-direction: column;

          .feature-subhead {
            text-align: left;
            padding: 0px 40px;
            font-weight: 400;
          }
        }

        .feature-content {
          $icon-width: 44px;
          $icon-pad: 4px;
          $list-width-min: 200px;
          $list-item-padding: 0.5em;

          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: flex-start;
          padding: 10px 34px 2em;

          li {
            margin-bottom: 10px;
            flex: 1;
            min-width: max(34%, $list-width-min);

            a,
            .faux-link {
              display: flex;
              // align-items: center;
              min-height: calc(#{$icon-width} + #{$icon-pad});
              padding: $list-item-padding;
              padding-left: calc(#{$icon-width} + #{$icon-pad} + #{$list-item-padding} + 10px);
              text-decoration: none;
              border-radius: 10px;
              color: inherit;
              @include transition-link;

              .feature-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                margin-left: calc((#{$icon-width} + #{$icon-pad} + #{$list-item-padding}) * -1);
                height: $icon-width;
                width: $icon-width;
                border-radius: 8px;
                border: 1px solid var(--qkn-light-blurple);
                background-color: var(--lavender-mist);

                img {
                  height: 22px;
                  width: 22px;
                }
              }

              .feature-text {
                text-align: left;
                display: inline-block;
                line-height: 1.3;
              }
            }

            a:hover {
              @include transition-link("hover");
              background-color: var(--light-steel-blue);
            }
          }
        }
      }
    }
  }
}

// Card Container Component configuration options
.cards-container {
  &[data-text-align-container-heading='Left'] .cards-wrapper .cards-heading {
    text-align: left;
    display: block;
    width: 100%;
  }

  &[data-text-align-container-heading='Center'] .cards-wrapper .cards-heading {
    text-align: center;
    display: block;
    width: 100%;
  }

  &[data-text-align-card-heading='Left'] .cards-wrapper .cards .simple-card .heading,
  &[data-text-align-card-body='Left'] .cards-wrapper .cards .simple-card span,
  &[data-text-align-card-body='Left'] .cards-wrapper .cards .simple-card span,
  &[data-text-align-card-cta='Left'] .cards-wrapper .cards .simple-card .cta {
    text-align: left;
  }

  &[data-text-align-card-heading='Center'] .cards-wrapper .cards .simple-card .heading,
  &[data-text-align-card-body='Center'] .cards-wrapper .cards .simple-card span,
  &[data-text-align-card-body='Center'] .cards-wrapper .cards .simple-card span,
  &[data-text-align-card-cta='Center'] .cards-wrapper .cards .simple-card .cta {
    text-align: center;
  }

  &[data-max-cards-per-row="2"] {
    .cards {
      max-width: 942px;
    }
  }

  $margin-per-card: $margin-horizontal-simple-cards * 2;

  @for $max-cards-per-row from 2 through 4 {
    @media (min-width: #{($min-width-simple-cards + $margin-per-card) * $max-cards-per-row}) {
      $selector: '';

      @if $max-cards-per-row ==2 {
        $selector: '&[data-max-cards-per-row=\'4\'],';
      }

      #{$selector} &[data-max-cards-per-row='#{$max-cards-per-row}'] {
        &[data-text-align-container-heading='Left'] .cards-wrapper>h2 {
          text-align: left;
          align-self: flex-start;
        }

        .cards-wrapper .cards {
          grid-template-columns: repeat($max-cards-per-row, 1fr);
        }
      }
    }
  }

  // Card fields ordering
  $row-size-image: auto;
  $row-size-heading: 1fr;
  $row-size-text: 1fr;

  &.image-heading-text .cards-wrapper .cards {
    .simple-card {
      grid-template-rows: $row-size-image $row-size-text;
      padding-top: 0;

      video {
        padding-top: 48px;
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 1em;

        .heading {
          align-self: center;
          height: auto;

          &.bold {
            font-weight: $font-weight-bold;
          }
        }
      }
    }
  }

  &.heading-image-text .cards-wrapper .cards {
    .simple-card {
      grid-template-rows: $row-size-heading $row-size-image $row-size-text;
    }
  }

  &.heading-text-image .cards-wrapper .cards {
    .simple-card {
      grid-template-rows: $row-size-text $row-size-image;
      padding-bottom: 0;

      video {
        padding-bottom: 40px;
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 1em;

        .heading {
          height: auto;
        }
      }
    }
  }
}
