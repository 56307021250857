@import "./global/reset";
@import "./global/base";
@import "./global/colors";
@import "./mixins/breakpoints";
@import "./global/typography";
@import "./global/transitions";

body {
  background-color: var(--white);
}
#page-wrapper {
  background-color: var(--white);
  max-width: $max-width;
  min-width: $min-width;
  margin: 0 auto;
  font-family: "Haffer";
  font-size: 16px;
}

.skip-link {
  position: absolute;
  opacity: 0;
  font-size: 19px;
  padding: 8px 12px 8px 16px;
}

.skip-link:focus {
  position: unset;
  opacity: 1;
  display: inline-block;
}

// Layouts
@import "./layouts/article";
@import "./layouts/sidebar";

// Components
@import "./components/components";
@import "./components/switch-hero";
@import "./components/secondary-messaging";
@import "./components/footer";
@import "./components/duplex-component";
@import "./components/media-modal";
@import "./components/slider";
@import "./components/tri-box-hero";
@import "./components/quad-box-hero";
@import "./components/sitewide-banner";
@import "./components/btt-btn";
@import "./components/image-accordion";
@import "./components/pcard-base";
@import "./components/pcard-full";
@import "./components/pcard-compact";
@import "./components/price-section";
@import "./components/navigation-menu";
@import "./components/faq-accordion";
@import "./components/product-selector";
@import "./components/divider";
@import "./components/multi-image";
@import "./components/product-compare-table";
@import "./components/banner";
@import "./components/countdown-component";
@import "./components/callout-container";
@import "./components/in-product";
@import "./components/table";
@import "./components/social-proof-bar";
@import "./components/spotlights";
@import "./components/embedded-media.scss";
@import "./components/secondary-claim-cta";
@import "./components/static-form-page";
@import "./components/fi-flow-hero";
@import "./components/biographical-card";
@import "./components/callout-container";
@import "./components/rich-text";
@import "./components/form-component";
@import "./components/cta";

// Support
@import "./support/home";
@import "./support/components/support-article";
@import "./support/components/hero";
@import "./support/components/support-contact";
@import "./support/components/support-getting-started";
@import "./support/components/support-video-tiles";
@import "./support/components/support-recent-updates";
@import "./support/components/support-try-quicken";
@import "./support/components/search-results";
@import "./support/components/support-popup";
@import "./support/vendor/magnific";
@import "./support/components/chatgpt-modal";
@import "./support/components/support-feedback";
@import "./support/components/agent-login-form";
@import "./support/components/support-topic-landing";
@import "./support/components/support-autocomplete";
@import "./support/components/support-banner";
@import "./support/components/agent-home.scss";
